import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';

const ButtonSave = props => (
    <Button color="primary" type={props.type ? props.type : 'button'}
        onClick={props.onClick ? props.onClick : onClick}
        className={'btn-labeled btn-save ' + props.className}
        disabled={props.disabled ? true : false}
    >
        <span className="btn-label">
            <i className={'fa fa-' + (props.disabled ? 'circle-notch fa-spin' : (props.icon ? props.icon : 'save'))} aria-hidden></i>
            </span>
        {props.children ? props.children : t('common:Save')}
    </Button>
)

const onClick = (e) => {
    let elType = e.target.getAttribute('type');

    if (elType !== 'submit') {
        const formEl = document.getElementById('form-api');
        if (!formEl) {
            return;
        }

        const submitEl = formEl.querySelector('button[type="submit"]');
        if (!submitEl) {
            return;
        }

        submitEl.click();
    }
}

export default ButtonSave;