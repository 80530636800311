import React from 'react';
import Calendar from 'react-calendar/dist/entry.nostyle';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import i18next from 'i18next';
import { reverse } from 'named-urls';

class CalendarSidebar extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        
        this.state = {
            date: new Date(),
            overview: {
                route: props.settings.overview ? props.settings.overview.route : null,
                path: props.settings.overview ? props.settings.overview.path : null
            }
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.bindCalendar();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unbindCalendar();
    }

    render() {
        return (
            <Calendar
                value={this.state.date}
                onClickDay={this.onClick}
                showNeighboringMonth={true}
                onActiveDateChange={this.onActiveDateChange}
                onClickMonth={this.onClickMonth}
                showWeekNumbers={false}
                prev2Label={false}
                next2Label={false}
                minDetail="year"
                locale={i18next.language ? i18next.language : 'nl'}
            />
        )
    }

    bindCalendar = () => {
        document.addEventListener('reloadSmallCalendar', this.handleReload);
        document.addEventListener('dateChanged', this.handleDateChanged);
        document.addEventListener('routeChanged', this.handleRouteChanged);
    }

    unbindCalendar = () => {
        document.removeEventListener('reloadSmallCalendar', this.handleReload);
        document.removeEventListener('dateChanged', this.handleDateChanged);
        document.removeEventListener('routeChanged', this.handleRouteChanged);
    }

    handleReload = (e) => {
        this.load(moment(e.detail.date));
    }

    handleDateChanged = (e) => {
        if (!this._isMounted) { return; }

        this.setState({ date: e.detail.date });

        if (this.calendarRef) {
            this.calendarRef.current.setActiveStartDate(e.detail.date);
        }
    }

    handleRouteChanged = (e) => {
        let { route, path } = e.detail;
        this.setState({ overview: { route: route, path: path } })
    }

    onClick = (value) => {
        const { route, path } = this.state.overview;
        if (!route || !path) { return; }

        this.props.actions.toggleSetting('asideToggled');

        this.props.history.push(reverse(route, { path: path, date: moment(value).format('YYYY-MM-DD') }));
    }

}

const mapStateToProps = state => ({ settings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSidebar);