import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from './store/actions';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { withNamespaces } from 'react-i18next';

import configureStore from './store/store';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import RouteChangeTracker from './RouteChangeTracker';
import GA4React from "ga-4-react";
import { ToastContainer } from 'react-toastify';

const store = configureStore();
const ga4react = new GA4React('G-X2X0X6MDGW');


class App extends Component {
  constructor(props) {
    super(props);
    ga4react.initialize();
  }

  componentDidMount() {
    const { settings } = store.getState();

    if (!settings) {
      return;
    }

    let { language, defaultLanguage } = settings;

    let i18nLang = this.props.i18n.language;
    i18nLang = i18nLang.substr(0, 2);

    if (!(['nl', 'fr', 'en'].includes(i18nLang))) {
      i18nLang = defaultLanguage;
    }

    if (i18nLang !== language) {
      this.props.i18n.changeLanguage(i18nLang);
      this.props.actions.changeSetting('language', i18nLang);
    }
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <>
          <RouteChangeTracker />
          <ToastContainer pauseOnFocusLoss={false} autoClose={4000} />
          <Routes />
        </>
      </BrowserRouter>
    );

  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default compose(
  connect(null, mapDispatchToProps),
  withNamespaces()
)(App);