import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import HeaderRun from './Header.run'
import { UtilUrl } from '../../utils/url.util';
import routes from '../../routing/routes';
import { API } from '../../services/api';
import DropdownLanguageSelect from '../dropdown/DropdownLanguageSelect';
import { t } from 'i18next';

class Header extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    logout = e => {
        e.preventDefault();

        this.props.actions.patientAccessKeyRemove();

        if (this.props.patient.urls) {
            API.get(this.props.patient.urls.logout, {})
                .then(() => this.redirect());
        } else {
            this.redirect();
        }
    }

    redirect = () => {
        let redirect = this.props.settings.autoRequestLink ? this.props.settings.autoRequestLink : this.props.settings.afterLoginUrl;
        this.props.actions.patientLoggedOut();
        this.props.history.push(redirect);
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    render() {
        const { patient, group } = this.props;

        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <div className="brand-logo">
                            <img className="img-fluid" src="images/mya-logo-white.svg" alt="MYA logo" />
                        </div>
                        <div className="brand-logo-collapsed">
                            <img className="img-fluid" src="images/mya-logo-white-collapsed.svg" alt="MYA logo" />
                        </div>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {this.props.patient && (
                            <li className="nav-item d-none d-md-block">
                                <span className="navbar-text nav-text">
                                    {t('header.welcome', { name: patient.patient.firstName + ' ' + patient.patient.lastName, nationalInsuranceNumber: patient.patient.nationalInsuranceNumber })}
                                </span>
                            </li>
                        )}

                        {this.props.patient && (group && group.settings.manage) && (
                            <li className="nav-item d-none d-md-block">
                                <a href={UtilUrl.generate(routes.patient.overview)} className="nav-link">
                                    <em className="icon-calendar mr-1"></em>
                                    {t('patient.appointment:title')}
                                </a>
                            </li>
                        )}

                        <DropdownLanguageSelect />
                        { /* START logout */}

                        {this.props.patient && (
                            <React.Fragment>
                                {/*<li className="nav-item d-none d-md-block">
                                    <Link to={{ pathname: UtilUrl.generate(routes.patient.overview) }} title="Logout" className="nav-link">
                                        <em className="icon-user"></em>
                                    </Link>
                                </li>*/}

                                <li className="nav-item d-md-block">
                                    <a className="nav-link" href="#" onClick={this.logout}>
                                        <em className="icon-logout"></em>
                                    </a>
                                </li>
                            </React.Fragment>

                        )}
                        { /* END logout */}
                    </ul>
                    { /* END Right Navbar */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings ? state.settings : {}, patient: state.patient ? state.patient : null, group: state.group ? state.group : null });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);