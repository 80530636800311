export const TOGGLE_SETTING = 'TOGGLE_SETTING';
export const CHANGE_SETTING = 'CHANGE_SETTING';
export const CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const CHANGE_POPUP = 'CHANGE_POPUP';
export const RESET_POPUPS = 'RESET_POPUPS';


/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function changeSetting(name, value) {
    return { type: CHANGE_SETTING, name, value };
}

/**
 * Toggle a setting value (only boolean)
 */
export function toggleSetting(name) {
    return { type: TOGGLE_SETTING, name };
}

export function changeNotification(id, value) {
    return { type: CHANGE_NOTIFICATION, id, value };
}

export function resetNotifications() {
    return { type: RESET_NOTIFICATIONS };
}

export function changePopup(value) {
    return { type: CHANGE_POPUP, value };
}

export function resetPopups() {
    return { type: RESET_POPUPS };
}