import { combineReducers } from 'redux';

import clientReducer from './client/client.reducer';
import groupReducer from './group/group.reducer';
import patientReducer from './patient/patient.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
    client: clientReducer,
    group: groupReducer,
    patient: patientReducer,
    settings: settingsReducer
});