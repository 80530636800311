import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

import SidebarRun from './Sidebar.run';

import CalendarSidebar from '../CalendarSidebar';
import MenuSidebar from '../../menu/MenuSidebar';
import { UtilUrl } from '../../utils/url.util';
import routes from '../../routing/routes';
import ButtonGroupOverview from '../buttons/ButtonGroupOverview';

class Sidebar extends Component {

    state = {
        collapse: {}
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator, this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
    }

    closeSidebar = () => {
        // this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        MenuSidebar
            .filter(({ heading }) => !heading)
            .forEach(({ name, path, submenu }) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path)
            })
        this.setState({ collapse });
    }

    navigator = route => {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some((p) => {
            p = p.replace(/\/$/, '');
            return this.props.location.pathname.indexOf(p) > -1
        })
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({ path }) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    render() {
        const { showGroupOverviewButton, entrance, showSidebarCalandar } = this.props.settings;
        
        let groupOverviewLink = null;
        if (entrance && showGroupOverviewButton && this.props.group.overviewUrl) {
            if (entrance.indexOf('schedule_type_') >= 0) {
                groupOverviewLink = UtilUrl.generate(routes.type.group, { path: this.props.group.overviewUrl });
            } else {
                groupOverviewLink = UtilUrl.generate(routes.group.overview, { path: this.props.group.overviewUrl });
            }
        }

        const { group } = this.props;

        // no group overview for Medina temporary
        if (group && (group.id === 62 || (group.client_count && group.client_count <= 1))) {
            groupOverviewLink = null;
        }

        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */}
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */}
                        <ul className="sidebar-nav">
                            <div className="sidebar-title py-2">
                                {/*this.props.group.name*/}
                            </div>

                            {showSidebarCalandar && (<CalendarSidebar {...this.props} />)}

                            {groupOverviewLink && (
                                <div className="text-center">
                                    <ButtonGroupOverview onClick={() => this.props.actions.toggleSetting('asideToggled')} to={{ pathname: groupOverviewLink }} />
                                </div>
                            )}
                        </ul>
                        { /* END sidebar nav */}
                    </nav>
                </div>
                { /* END Sidebar (left) */}
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings ? state.settings : {}, group: state.group ? state.group : {} });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((withRouter(Sidebar)));
