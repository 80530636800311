import React from 'react';
import DropdownLanguageSelect from '../dropdown/DropdownLanguageSelect';

const BasePage = props => (
    <div className="wrapper">
        <header className="topnavbar-wrapper" style={{ position: 'absolute', boxShadow: 'none', zIndex: 2, width: '100%' }}>
            { /* START Top Navbar */}
            <nav className="navbar topnavbar">
                { /* START Right Navbar */}
                <ul className="navbar-nav ml-auto flex-row">
                    <DropdownLanguageSelect />
                </ul>
                { /* END Right Navbar */}
            </nav>
            { /* END Top Navbar */}
        </header>

        {props.children}
    </div>
)

export default BasePage;
